/**
 * Type: ページ
 * What: 京都天ぷら 花いかだ
 */
import { useEffect } from 'react';

// Data Props Template
const Template = () => {
  // 初回DOM
  useEffect(() => {
    window.location.href = 'https://e-zofukuoka.com/';
  });
  return true;
};

export default Template;
